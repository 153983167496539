import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@/components/responsive';

export function PrivacyPolicy() {
  return (
    <Wrapper>
      <h2>Visually.io Privacy Policy</h2>

      <p>
        Visually.io &quout;the App&quout; provides an editing and
        personalization tool &quout;the Service&quout; to merchants who use
        Shopify to power their stores. This Privacy Policy describes how
        personal information is collected, used, and shared when you install or
        use the App in connection with your Shopify-supported store.
      </p>

      <h4>Personal Information the App Collects</h4>
      <p>
        When you install the App, we are automatically able to access certain
        types of information from your Shopify account: Online Store data and
        Theme files.
      </p>
      <p>
        When you visit or log in to our website, cookies and similar
        technologies may be used by our online data partners or vendors to
        associate these activities with other personal information they or
        others have about you, including by association with your email or home
        address. We (or service providers on our behalf) may then send
        communications and marketing to these email or home addresses. You may
        opt out of receiving this advertising by visiting{` `}
        <a href="https://app.retention.com/optout">Here</a>
      </p>
      <p>
        Additionally, we collect the following types of personal information
        from you and/or your customers once you have installed the App: Store
        name, email, merchant name.
      </p>
      <p>
        Information about you and others who may access the App on behalf of
        your store, such as your name, address, email address, phone number, and
        billing information;
      </p>
      <p>
        Information about individuals who visit your store, such as their IP
        address, web browser details, time zone, and information about the
        cookies installed on the particular device.
      </p>
      <p>
        We collect personal information directly from the relevant individual,
        through your Shopify account, or using the following technologies:
        “Cookies” are data files that are placed on your device or computer and
        often include an anonymous unique identifier. For more information about
        cookies, and how to disable cookies, visit
        <a href="http://www.allaboutcookies.org">
          http://www.allaboutcookies.org
        </a>
        . “Log files” track actions occurring on the Site, and collect data
        including your IP address, browser type, Internet service provider,
        referring/exit pages, and date/time stamps. “Web beacons,” “tags,” and
        “pixels” are electronic files used to record information about how you
        browse the Site. We also use Google Analytics, Fullstory and Clarity.
      </p>

      <h4>Security and Data Retention</h4>
      <p>
        We implement measures to secure your Information and to avoid risks of
        damage, loss of information and unauthorized access or use of
        information. However, these measures do not provide absolute information
        security.
      </p>

      <h4>Sharing Your Information</h4>
      <p>
        We do not share your Personal Information with any third parties. We may
        share your Personal Information to comply with applicable laws and
        regulations, to respond to a subpoena, search warrant or other lawful
        request for information we receive, or to otherwise protect our rights.
      </p>

      <h4>Your Rights</h4>
      <p>
        If you are a European resident, you have the right to access personal
        information we hold about you and to ask that your personal information
        be corrected, updated, or deleted. If you would like to exercise this
        right, please contact us through the contact information below.
      </p>
      <p>
        Additionally, if you are a European resident we note that we are
        processing your information in order to fulfill contracts we might have
        with you (for example if you make an order through the Site), or
        otherwise to pursue our legitimate business interests listed above.
        Additionally, please note that your information will be transferred
        outside of Europe, including to Canada and the United States.
      </p>

      <h4>Changes</h4>
      <p>
        We may update this privacy policy from time to time in order to reflect,
        for example, changes to our practices or for other operational, legal or
        regulatory reasons.
      </p>

      <h4>Contact us</h4>
      <p>
        If you would like to access, correct, amend or delete any personal
        information, or require any additional information about our privacy
        policy please contact us at{` `}
        <a href="mailto:support@visually.io">support@visually.io</a>
      </p>
    </Wrapper>
  );
}

const Wrapper = styled(`div`)`
  padding: 2rem 2rem 8rem 2rem;
  max-height: 100vh;
  overflow-y: scroll;
  line-height: 1.4;

  ${breakpoints.up(`md`)} {
    padding: 0 20% 5rem 20%;
  }

  h2 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.7rem;
  }

  p {
    font-size: 1.4rem;
  }

  a {
    margin-left: 0.5rem;
  }
`;
